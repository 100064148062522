<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    <default-drawer-toggle class="hidden-sm-and-down" />
    <!-- <v-toolbar-title
      class="hidden-lg-and-down font-weight-light"
      v-text="breadСrumbs"
    /> -->
    <v-breadcrumbs :items="breadСrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-spacer />
    <v-toolbar-title
      class="font-weight-normal text-uppercase"
      :class="{
        'text-h3 ': $vuetify.breakpoint.lgAndUp,
        'text-h4 ': $vuetify.breakpoint.mdAndUp,
        'subtitle-1': $vuetify.breakpoint.smAndDown,
      }"
      v-text="title"
    />
    <v-spacer />
    <!-- <default-theme-pick></default-theme-pick> -->
    <!-- Для роли "Customer" отображаем -->
    <default-go-home v-if="userRole === 'Customer'" />
    <default-account v-if="userRole === 'Customer'" />

    <!-- Для всех остальных ролей отображаем -->
    <default-go-home v-else />
    <default-go-incident v-if="userRole !== 'Customer'" />
    <default-go-objects v-if="userRole !== 'Customer'" />
    <default-account v-if="userRole !== 'Customer'" />
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";
import { mapGetters } from "vuex"; 


export default {
  name: "DefaultBar",

  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "./widgets/Account"
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
    DefaultThemePick: () =>
      import(
        "./widgets/ThemePick.vue"
      ),
    DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        "./widgets/GoHome"
      ),
    DefaultGoContact: () =>
      import(
        /* webpackChunkName: "default-go-contact" */
        "./widgets/GoContact"
      ),
    DefaultLocale: () =>
      import(
        /* webpackChunkName: "default-locale" */
        "./widgets/Locale"
      ),
    DefaultGoIncident: () =>
      import(
        /* webpackChunkName: "default-locale" */
        "./widgets/GoIncident"
      ),
    DefaultNotifications: () =>
      import(
        /* webpackChunkName: "default-notifications" */
        "./widgets/Notifications"
      ),
    DefaultSearch: () =>
      import(
        /* webpackChunkName: "default-search" */
        "./widgets/Search"
      ),
    DefaultGoObjects: () =>
      import(
        /* webpackChunkName: "default-go-objects" */
        "./widgets/GoObjects"
      )  
  },
  data() {
    return {};
  },
  computed: {
    ...get("auth", ["authorized", "authstatus"]),
    ...get("app", ["title"]),
    ...sync("app", ["drawer", "mini"]),
    userRole() {
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      return userData.role;
    },
    // name: get("route/name"),
    breadСrumbs: get("route/meta@items"),
  },
  methods: {},
  mounted() {
    // console.log(this.$store.$route);
  },
};
</script>
